import { Component } from '@angular/core';

@Component({
    selector: 'app-pageNotFound',
    templateUrl: './pageNotFound.component.html'
})
export class pageNotFound  {
    
    constructor() { }

   
}