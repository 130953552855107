import { Students } from "./students.model";
import { FilesUploadModel } from "./files.model";

export class PlanViewModel {
    Id: number;
    Code: string;
    Name: string;
    Description: string;
    PlanTypeId: number;
    BranchId: string;
    SubId: string;
    IsDeleted: boolean;
    IsActive: boolean;
    CreatedBy: string;
    CreationDate: string;
    ModifiedBy: string;
    ModificationDate: string;
    weekStr :string;
    weekStrNew :string;
    MaxTripTimeMins: number;
    WaitingTimeMins: number;
    attachementList : FilesUploadModel[]=[];
    TripStartTime: string;
    TripEndTime: string;
    PlanStartDate: Date;
    PlanEndDate: Date;
    
    PlanTrips: PlanTripsViewModel[] ;

}








export class PlanTripsViewModel {
    Id: number;
    PlanId: number;
    SeatCapacity : number;
    BusId: string;
    plateNo:string;
    code : string;
    DriverId: string;
    SupervisorId: string; 
    DriverName: string;
    SupervisorName: string; 
    StartLandmarkName : string; 
    DestLandmarkName : string; 
    SourcePOI: string;
    DestinationPOI: string;
    RouteString: string;
    StartTime: Date;
    EndTime: Date;
    TripDurationMins: number;
    TripMileage: number;
    StudentsCount: number;
    IsDeleted: boolean;
    TotalTime:number;
    TotalDistance :number;
    PlannedTripStudents :Students[];
}


// export class StudentModel {
//     Id: number;
//     BusArrivalTime: string = '12';
// }
