import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { Configuration } from './shared/configurations/shared.config';
import { pageNotFound } from './shared/components/pageNotFound/pageNotFound.component';
import { AuthGuard } from './shared/Providers/auth-guard.provider';

export const appRoutes: Routes = [

    {
        path: '',
        redirectTo: 'Schools/studentlist',
        pathMatch: 'full'
    },
    {
        path: Configuration.modulePrefixes.SchoolsManagement,
        loadChildren: './Schools/schools.module#SchoolsModule',
        canActivate: [AuthGuard]
    },
    {
        path: Configuration.modulePrefixes.CheckinoutManagement,
        loadChildren: './vehicle-checkup/vehicle-checkup.module#VehicleCheckupModule',
    },{
        path: Configuration.modulePrefixes.GPSReporting,
        loadChildren: './GPSDashboard/GPSReporting.module#GPSReportingModule',
    },
    {
        path: '**',
        component: pageNotFound,
        canActivate: [AuthGuard]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(appRoutes, { preloadingStrategy: PreloadAllModules })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
