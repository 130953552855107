import { Directive, Input } from '@angular/core';
import { Validator, NG_VALIDATORS, AbstractControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Directive({
    selector: '[compare]',
    providers: [{ provide: NG_VALIDATORS, useExisting: CompareValidatorDirective, multi: true }]
})
export class CompareValidatorDirective implements Validator {

    @Input('compare') controlNameToCompare: string;

    validate(c: AbstractControl): { [key: string]: any } | null {
        // control value (e.g. password)
        const controlToCompare = c.root.get(this.controlNameToCompare);
        if (c.value == null || c.value.length == 0)
            return null;
        if (controlToCompare) {
            const subscription: Subscription = controlToCompare.valueChanges.subscribe(() => {
                c.updateValueAndValidity();
                subscription.unsubscribe();
            });
        }

        return controlToCompare && controlToCompare.value !== c.value ? { 'compare': true } : null;
    }
}
