import { WorkOrderRouteObject } from "./buss.model";
import { PlannedTripStudentModel } from "./PlannedTripStudentModel";

export class BusModel {
    plateNo : string;
    code : string;
    Id : string ;
    StartLandmark : string; 
    DestLandmark : string;  
    StartLandmarkName : string; 
    DestLandmarkName : string; 
    Seats : number;
    Driver: string;
    Supervisor : string; 
    Number: string;
    PlatNumber: string;
    CategoryId: number = -1;
    SubscriberId: number = 1;
    Selected: boolean = false;
    SeatCapacity: number;
    DriverName: string;
    DriverId  :string; 
    SupervisorId : string;
    SupervisorName : string;
    StartLocationLatitude : string;
    StartLocationLongitude : string;
    EndLocationLatitude : string;
    EndLocationLongitude : string;
    Route: WorkOrderRouteObject = new WorkOrderRouteObject();
    Students: PlannedTripStudentModel[] = [];
    StartTime: string; 
    EndTime:string;
    displayResult: boolean;
}
