import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { vehicleHistory } from '../../../Schools/dataModel/vehicleHistory.model';
import { MapService } from '../../Services/mapservice/map-service';
import { Options, ChangeContext } from 'ng5-slider';

@Component({
  selector: 'app-playback',
  templateUrl: './playback.component.html',
  styleUrls: ['./playback.scss']
})
export class PlaybackComponent implements OnInit {
  @Input('disable') disable: boolean = false;
  @Input('routing') routing: vehicleHistory[] = [];


  Move: number ;
  value: number = 100;
  options: Options;
  interval: any;
  i: number = 0;
  constructor(private _mapService: MapService) {
    this._mapService.startMode.subscribe(res => {
      this.playing = res;

    });
  }
  playing: boolean = false;

  onUserChange(changeContext: ChangeContext): void {
    debugger
    console.log(changeContext.value)
    this._mapService.onUserChange(changeContext.value, this.routing)
  }
  ngOnInit() {
    this._mapService.Move.subscribe(res => {
      this.Move = res;
    });
    this.options = {
      floor: 0,
      ceil: 0,
      translate: (value: number): string => {
          return '';
      },
    };
  }
  PlayMode() {
    //debugger
    this.playing = true;
    if (this.routing) {
      this.options = {
        floor: 0,
        ceil: this.routing.length,
        translate: (value: number): string => {
            return '';
        },
      };
    }
    this.i = this._mapService.getIndex();
    this._mapService.onUserChange(this.i, this.routing);

  }

  ReplayMode() {
    this.i = 0;
    this.playing = true;
    if (this.routing) {
      this.options = {
        floor: 0,
        ceil: this.routing.length,
        translate: (value: number): string => {
          return '';
         
        },
      };
    }
    this._mapService.onUserChange(this.i, this.routing);
  }
  StopMode() {
    clearInterval(this._mapService.getObj());
    this.playing = false;

  }




}
