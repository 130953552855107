import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
// rxjs
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

//services
import { AuthenticationService } from '../Services/authentication/authentication.service';
import { LoaderService } from '../loader.service';

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {
    constructor(private Auth: AuthenticationService , private loader:LoaderService) { }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.Auth.validUser()) {
            let token = this.Auth.getToken();
            if(token)
            req = req.clone({ headers: req.headers.append('Authorization', token).append('StaffId',this.Auth.StaffId) });
        }

        this.loader.PreloaderIcreaseCount();
        return next.handle(req).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    let token = event.headers.get('Authorization');
                    if (token)
                        this.Auth.updateToken(token);
                     this.loader.PreloaderDecreaseCount();
                }
                return event;
            }),
            catchError(x => this.handleAuthError(x)
            ));
    }

    private handleAuthError(err: HttpErrorResponse): Observable<any> {
        this.loader.PreloaderDecreaseCount();
        //handle your auth error or rethrow
        if (err.status === 401 || err.status === 403) {
            //navigate /delete cookies or whatever
            this.Auth.logout();
            // if you've caught / handled the error, you don't want to rethrow it unless you also want downstream consumers to have to handle it as well.
            return of(err.message);
        }
        return throwError(err);
    }
}