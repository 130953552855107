export let Configuration = {
     Ports: {
        AltairPort: 'http://localhost:4679',
        GpsPort: 'http://localhost:9000',
        RenCarsPort: 'http://localhost:32067'
    },
    defaultMapParams: {
        zoom: 10,
        mapType: 'google',
        centerX: 31.259443,
        centerY: 30.099036,
        minZoom: 2,
        maxZoom: 20
    },
    tokens:
    {
        GoogleMapDirectionsToken: 'AIzaSyB_HWxIIAw5vdXy4zgFh3KKEj6BAc9MWI4'
    },
    urls: {
        webApiEndpoint: "http://localhost:32300/api/",
        GoogleMapUrl: 'http://mt1.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
        ESRIMapUrl: 'https://server.arcgisonline.com/arcgis/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}',
        calculateRouteUrl: 'MapUtils/calculateRoute',

        apiAccountUrl: {
            apiGetPermissionsURL: 'account/staffPermissions'
        },
        GPSReportingUrl :{
            apiGetMostCoveargeMileage : 'GPSReportingDashboards/GetMostCoveargeMileage',
            apiGetLeastCoveargeMileage : 'GPSReportingDashboards/GetLeastCoveargeMileage',
            apiGetEventsTypesPercentage : 'GPSReportingDashboards/GetEventsTypesPercentage',
            apiGetViolationTypesPercentage : 'GPSReportingDashboards/GetViolationTypesPercentage',
            apiGetTotalIdle : 'GPSReportingDashboards/GetTotalIdle',
            apiGetTotalViolation : 'GPSReportingDashboards/GetTotalViolation',
            apiGetTotalDisconnect : 'GPSReportingDashboards/GetTotalDisconnect',
            apiGetViolationsPerHour : 'GPSReportingDashboards/GetViolationsPerHour',
            apiGetViolationsPerBranch : 'GPSReportingDashboards/GetViolationsPerBranch',
            apiGetVehicleLicenseStatus : 'GPSReportingDashboards/GetVehicleLicenseStatus'

            
            
        },
        lookupsUrl: {
            apiGetGenderNewURL: 'lookup/getGender',
            apiGetRelativeRelationURL: 'lookup/getRelations',
            apiGetGuardiansURL: 'lookup/getGuardians',
            apiGetStudentsURL: 'lookup/getStudents',
            apiGetGradeURL: 'lookup/getGrade',
            apiGetClassURL: 'lookup/getClass',
            apiGetBranchURL: 'lookup/getBranch',
            apiGetSubBranchURL: 'lookup/getSubBranch',
            apiGetIdentityTypesURL: 'lookup/getIdentityTypes',
            apiGetPlantypeURL: 'lookup/getPlanType',
            apiGetPlans: 'lookup/getplans',
            apiGetDrivers: 'lookup/getstaff',
            apigetsupervisors: 'lookup/getstaff',
            apigetvehicles: 'lookup/getvehicles',
            apiGetbusesURL: 'lookup/getBuses',
            apiGetBusBySubIDURL : 'lookup/getBusBySubID',
            apiGetStaffURL: 'lookup/getStaff',
            apiGetLandmarkURL: 'lookup/getLandmark',
            apiGetLandmarkWithLocationURL: 'lookup/getLandmarkWithLocation',

            apiGetTripstatus:'lookup/gettripstatus',
            apiGetStudentsByGradeIdURL: 'lookup/getStudentsByGradeId'
        }, SearchStudentsUrl: {
            apiGetSearchStudentURL: 'student/getStudentList',
            apiGetStudentsByIDURL: 'student/getStudentsByID',
            apiPostStudentURL: 'student/postStudent',
            apiUploadStudentURL: 'student/upload',
            apiUploadGaurdianURL: 'guardian/upload',
            apiPostChangeBusSubscriptuonStudentURL: 'student/postBusSubscription',
            apiGetCheckStudentInProgressTripURL: 'student/checkStudentInProgressTrip',
            apiDeleteStudentByIdURL: 'student/deleteStudentById',
            apiexporttoexcel:'student/exporttoexcel',
            apiexporttoexcelGuardian:'student/exporttoexcelguardian'

        }, Plans: {
            apiGetStudentByBranchIdAndSubBranchIdURL: 'student/getStudentByBranchIdAndSubBranchId',
            apiGetGradeByBranchIdAndSubBranchIdURL: 'student/getGradeByBranchIdAndSubBranchId',
            apiGetBusByBranchIdAndSubBranchIdURL: 'student/getBusByBranchIdAndSubBranchId',
            apiPostPlan: 'student/postPlan',
            apiGetJobUtiliesUrl: 'MapUtils/applyJob',
            apiGetRouteUtiliesUrl: 'MapUtils/GetRoutes',
            apiGetStopUtiliesUrl: 'MapUtils/GetStops',
            apiGetPlannedTripByIDUrl : 'plans/getPlannedTripByID',
            apiPostPlanTripUrl : 'plans/updatePlanTrip',
            apiGetPlannedTripsUrl : 'plans/getPlannedTrips',
            apiDeletedPlannedTripsUrl : 'plans/deletedPlannedTrips',
            apipostexportplanstoexcel: 'plans/exportplanstoexcel',


        }, Trips: {
            apiGetTripByIdUrl: 'trips/getTripById',
            apiGetBusByIdUrl: 'trips/getBusById',
            apiGetVehiclehistoryUrl: 'cache/getvehiclehistory',
            apiPutUpdateTripUrl: 'trips/updateTrip',
            apiPutUpdateStudentStatusID : 'trips/updateStudentStatusID',
            apipostexporttripstoexcel:'trips/exporttripstoexcel'

        }, UploadFile: {
            apiUploadFile: 'scan/UploadFile',
            apiDeletedFile: 'scan/DeletedFile',
            apiDownloadFile: 'scan/download'

        },
        GuardianUrl: {
            apiGetGuardainByIdURL: 'guardian/getById',
            apiSaveGuardainURL: 'guardian/save',
            apicheckUniqueURL: 'guardian/checkUnique',
            apiGetStudentGuardiansByStudentIdURL: 'guardian/getStudentGardians',
            apiDeletetGuardianByIdURL: 'guardian/DeleteGardian',
            apiRemoveGuardianBySGIdURL: 'guardian/RemoveGardian'
        }

    },
    modulePrefixes: {
        SchoolsManagement: 'Schools',
        CheckinoutManagement: 'checkinout',
        GPSReporting : 'GPSReporting'
    },

    keys: {

        //planning service Keys
        LogisticsServiceToken: 'IBrkRtJb3z6Bp2sEGCAVIQBfZPCRGwhDxvMqMS1RtVnzz0O356IMaSekOQDjQ9WGHo-MUCYanlkLgieO8ePONcmytud7N553mfvSU-CjAdc0gIzEZm3uBKtVNjgE7wPdio0ptHLYrL9aIB-WKWmdIg..',
        LogisticsServiceDirectionsLanguage: "en",
        UploadExtension: "JPG,JPEG,TIF,GIF,PNG,BMP",
        maxFileSize: 5242880,
        LogisticsServiceTimeUnits: 'Minutes',
        LogisticsServiceDistanceUnits: 'Kilometers',
        LogisticsServiceUturnPolicy: 'NO_UTURNS',
        LogisticsServicePopulateDirections: "true",
        //cookie Domain 
        CookieDomain:""

    },

    messages: {
        defaultErrorMessage: 'unexpectedErrorMessage'
    },

    paths: {
        loginUrl: '/users/login',
        studentList: '/Schools/studentlist',
        planList: '/Schools/planlist',
        studentsbuses: '/Schools/plan/studentsbuses',
        plandetails: '/Schools/plan',
        generatedtrips: '/Schools/plan/generatedtrips',

    },
    GridSetting: {
        Pagesize: 20
    },
    Nagm: {
        IsTransactionStatusChangeEditable: false,
        RFRLink: "/WebPages/RequestForRepair/Create.aspx",
        RFRAccidentLink: "/WebPages/RequestForRepair/AccidentCreate.aspx"
   
      
  },

    Logo: {
    //   src: '/dist/assets/MasaratBranding/styles/img/logo_OLD.png'
     // src: '/dist/assets/fms_branding/styles/img/logo.png'
      src: '/dist/assets/PostalBranding/styles/img/logo-g.png'

  }

};

