import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { FilesUploadModel } from '../dataModel/files.model';
import { Configuration } from '../../shared/configurations/shared.config';

@Injectable({
  providedIn: 'root'
})
export class UploadfilesService {

  constructor(private http: HttpClient) { }

  uploadFiles(files) {
    let formData: any = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("uploadFile", files[i], files[i]['name']);
    }
    return this.http.post<FilesUploadModel>(Configuration.urls.webApiEndpoint + Configuration.urls.UploadFile.apiUploadFile, formData);
  }

  deleteFiles(pathName, id) {
    let Params = new HttpParams();
    // Begin assigning parameters
    Params = Params.append('pathName', pathName);
    Params = Params.append('id', id);
    return this.http.delete(Configuration.urls.webApiEndpoint + Configuration.urls.UploadFile.apiDeletedFile, { params: Params });
  }

  download(filePath: string, fileName: string, id: number, type: number) {
    let Params = new HttpParams();
    // Begin assigning parameters
    Params = Params.append('fileName', fileName);
    Params = Params.append('filePath', filePath);
    Params = Params.append('id', id.toString());
    Params = Params.append('type', type.toString());
    return this.http.get(Configuration.urls.webApiEndpoint + Configuration.urls.UploadFile.apiDownloadFile, { params: Params, responseType: "blob" });
  }
}
