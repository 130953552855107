import { Component, OnInit } from '@angular/core';
import { alert } from '../../dataModels/alert/alert';
import { alertService } from '../../Services/alert/alert.service';

@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html' ,
    styleUrls:['./alert.component.scss']
})
export class alertComponent implements OnInit {
    alerts:alert[] = [];
    constructor(private alertService: alertService) { }

    ngOnInit(): void {
        this.alertService.getAlert().subscribe((alert: alert) => {
            if (!alert) {
                // clear alerts when an empty alert is received
                this.alerts = [];
                return;
            } 
            // add alert to array
            this.alerts.push(alert);
        });
     }
     onClosed(dismissedAlert: alert): void {
        this.alerts = this.alerts.filter(alert => alert !== dismissedAlert);
      }
}