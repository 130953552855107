import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';

//services
import { CookieService } from 'ngx-cookie-service';
import { MenuLinkService } from '../menu/menu-link.service';
import { Configuration } from '../../configurations/shared.config';

@Injectable()
export class AuthenticationService {
  
    constructor(private CookieSrvc: CookieService, private menuservice: MenuLinkService) {

    }
    isLogged: boolean = false;
    loggedIn$ = new BehaviorSubject<boolean>(this.isLogged);

    userName: string = this.getCurrentuserName();
    UserId: string = this.getCurrentUserId();
    SubId: string = this.getCurrentSubId();
    StaffId: string = this.getCurrentStaffId();
    culture: string = this.getCurrentculture();
    subLogo: string = this.getSublogo();
    getCurrentUser() {
        if (this.CookieSrvc.get('SSOCookie')) {
            try {
                return this.CookieSrvc.get('SSOCookie')
            }
            catch (err) {
                return null;
            }
        }
        return null;
    }
    getSublogo() {
        let Cookie: string = this.getCurrentUser();
        if (Cookie)
            return Configuration.Ports.AltairPort + Cookie.split('*')[8].replace('~', '');
        else return "";

    }

    validUser() {
        var userdata = this.getCurrentUser();
        if (userdata != null) {
            this.userName =this. decodeBase64(userdata.split('*')[15]);
            this._setLoggedIn(true);
            return true;
        }
        this._setLoggedIn(false);
        return false;
    }

    private _setLoggedIn(value: boolean) {
        // Update login status subject
        if (this.isLogged != value) {
            this.loggedIn$.next(value);
            this.isLogged = value;
        }

    }

    getCurrentUserId(): string {

        var userdata = this.getCurrentUser();
        if (userdata) {
            let userdataarray: any[] = userdata.split('*');
            if (userdataarray.length > 2)
                return userdataarray[0]
            else
                return '';
        }
        return '';
    }
    getCurrentSubId(): string {

        var userdata = this.getCurrentUser();
        if (userdata) {
            let userdataarray: any[] = userdata.split('*');
            if (userdataarray.length > 2)
                return userdataarray[1]
            else
                return '';
        }
        return '';
    }
    getCurrentStaffId(): string {
        var userdata = this.getCurrentUser();
        if (userdata) {
            let userdataarray: any[] = userdata.split('*');
            if (userdataarray.length > 11)
                return userdataarray[12]
            else
                return '';
        }
        return '';
    }
    private getCurrentuserName(): string {
        var userdata = this.getCurrentUser();
        if (userdata) {
            let userdataarray: any[] = userdata.split('*');
            if (userdataarray.length > 15)
            {
                let a=this.decodeBase64(userdataarray[15]);
                console.log(userdataarray[15]);
                console.log(a);
              return a;
            }
            else
                return '';
        }
        return '';
    }
    getCurrentculture(): string {
        var userdata = this.getCurrentUser();
        if (userdata) {
            let userdataarray: any[] = userdata.split('*');
            if (userdataarray.length > 3) {
                let culture = userdataarray[4].split('-')
                return culture[0]
            }
            else
                return 'en';
        }
        return 'en';
    }
    setCulture(culture: string) {
        var userdata = this.getCurrentUser();
        if (userdata) {
            let userdataarray: string[] = userdata.split('*');
            if (userdataarray.length > 3) {
                userdataarray[4] = culture;
                let CookieDomain = (Configuration.keys.CookieDomain && Configuration.keys.CookieDomain != '') ? Configuration.keys.CookieDomain : null
                this.CookieSrvc.set('SSOCookie', userdataarray.join('*'), null, '/', CookieDomain);
            }
        }
    }

    getToken() {
        if (this.CookieSrvc.get('Authentication')) {
            try {
                return this.CookieSrvc.get('Authentication')
            }
            catch (err) {
                return null;
            }
        }
        return null;
    }

    logout() {
        this.removeUser();
        window.location.href = this.menuservice.getLink('login', 'Altair');
    }
    removeUser() {
        let CookieDomain = (Configuration.keys.CookieDomain && Configuration.keys.CookieDomain != '') ? Configuration.keys.CookieDomain : null
        this.CookieSrvc.deleteAll('/', CookieDomain);
        this._setLoggedIn(false);
    }

    updateToken(token: string) {
        let CookieDomain = (Configuration.keys.CookieDomain && Configuration.keys.CookieDomain != '') ? Configuration.keys.CookieDomain : null
        this.CookieSrvc.set('Authentication', token, null, '/', CookieDomain);
    }

    /**
 * Encode a string of text as base64
 *
 * @param data The string of text.
 * @returns The base64 encoded string.
 */
 encodeBase64(data: string) {
    if (typeof btoa === "function") {
        return btoa(data);
    } 
}

/**
 * Decode a string of base64 as text
 *
 * @param data The string of base64 encoded text
 * @returns The decoded text.
 */
 decodeBase64(data: string) {
    if (typeof atob === "function")  {
      let   a=  decodeURIComponent(escape(atob(data))); //atob(data);
      a = a.replace(/"/g, "")
      return a;
    } 
   
}
}
