import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
//Component
import { AppComponent } from './app.component';
import { AppHeader } from './component/header.component';
import { Sidemenu } from './component/side_menu.component';
//import { AmazingTimePickerModule } from 'amazing-time-picker'; // this line you need

//modules
import { SharedModule } from './shared/shared.module';
//import { CheckInOutModule } from './CheckInOut/check-in-out.module';
import { AppRoutingModule } from './app-routing.module';
import { MapService } from './shared/Services/mapservice/map-service';
import { BsDropdownModule } from 'ngx-bootstrap';
import { HttpModule } from '@angular/http';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
// import { GPSReportingComponent } from './GPSDashboard/gpsreporting/gpsreporting.component';
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './dist/assets/i18n/', '.json');
}
@NgModule({
  declarations: [
    AppComponent,
    AppHeader,
    Sidemenu,
    // GPSReportingComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpModule,
    HttpClientModule,
    SharedModule,
    //CheckInOutModule,
    //CheckInOutRoutingModule,
    AppRoutingModule,
    BsDropdownModule.forRoot()
    ,
    TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
        }
      })
    //AmazingTimePickerModule 
  ],
  /* exports: [
    AppRoutingModule
  ] */
  providers: [MapService],
  bootstrap: [AppComponent]
})
export class AppModule { }
