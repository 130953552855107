import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { studentSearch } from '../dataModel/student-search.model';
import { Configuration } from '../../shared/configurations/shared.config';
import { Students } from '../dataModel/students.model';
import { AuthenticationService } from '../../shared/Services/authentication/authentication.service';
import { RequestOptions } from '@angular/http';

@Injectable({
  providedIn: 'root'
})

export class StudentService {

  thesearch: studentSearch;
  thesubid: string;
  constructor(
    private http: HttpClient,
    private _authenticationService: AuthenticationService
  ) { }

  exportAsExcel(searchobj: studentSearch) {
   // debugger
    return this.http.post(Configuration.urls.webApiEndpoint + Configuration.urls.SearchStudentsUrl.apiexporttoexcel, searchobj);
  }
  exportAsExcelGuardian(searchobj: studentSearch) {
    return this.http.post(Configuration.urls.webApiEndpoint + Configuration.urls.SearchStudentsUrl.apiexporttoexcelGuardian, searchobj)
  }
  getStudentList(myObj: studentSearch) {

    return this.http.post(Configuration.urls.webApiEndpoint + Configuration.urls.SearchStudentsUrl.apiGetSearchStudentURL, myObj);
  }
  getStudentByID(id: number) {
    return this.http.get<Students>(Configuration.urls.webApiEndpoint + Configuration.urls.SearchStudentsUrl.apiGetStudentsByIDURL + "?id=" + id);
  }
  saveStudent(student: Students) {
    debugger;
    return this.http.post(Configuration.urls.webApiEndpoint + Configuration.urls.SearchStudentsUrl.apiPostStudentURL, student);
  }
  uploadStudents(students: object) {
    return this.http.post(Configuration.urls.webApiEndpoint + Configuration.urls.SearchStudentsUrl.apiUploadStudentURL, students);
  }
  uploadGuardians(guardians: object) {
    return this.http.post(Configuration.urls.webApiEndpoint + Configuration.urls.SearchStudentsUrl.apiUploadGaurdianURL, guardians);
  }
  changeBusSubscriptuon(studentId: string) {

    return this.http.get(Configuration.urls.webApiEndpoint + Configuration.urls.SearchStudentsUrl.apiPostChangeBusSubscriptuonStudentURL + "?id=" + studentId + "&userId=" + this._authenticationService.UserId  );

  }

  checkSubscribe(id : string){
    return this.http.get(Configuration.urls.webApiEndpoint + Configuration.urls.SearchStudentsUrl.apiGetCheckStudentInProgressTripURL + "?id=" + id   );

  }
 



  deleteStudentById(stdID: string) {
    let Params = new HttpParams();
    // Begin assigning parameters
    Params = Params.append('id', stdID);
    Params = Params.append('userId', this._authenticationService.UserId);
    return this.http.delete(Configuration.urls.webApiEndpoint + Configuration.urls.SearchStudentsUrl.apiDeleteStudentByIdURL, { params: Params });
  }

}
