import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';

import { FilesUploadModel } from '../../dataModel/files.model';
import { UploadfilesService } from '../../services/uploadfiles.service';
import { alertService } from '../../../shared/Services/alert/alert.service';
import { Configuration } from '../../../shared/configurations/shared.config';


@Component({
  selector: 'app-uploadfiles',
  templateUrl: './uploadfiles.component.html',
  styleUrls: ['./uploadfiles.component.scss']
})


export class UploadfilesComponent implements OnInit {

  constructor(public _uploadFilesSrvc: UploadfilesService, private alert: alertService) {
    this.extensions = Configuration.keys.UploadExtension.split(',').map(x => x.toLowerCase())
    this.maxFileSize = Configuration.keys.maxFileSize;
  }
  @ViewChild('fileUpload') myFileControl: ElementRef;
  FilesUpload: FilesUploadModel;
  extensions: string[];

  @Input() IsAllowPdf: boolean;
  @Input() maxFileSize: number;
  @Input() uploadType: number = 0;
  @Input() IsDisabled: boolean = false;
  @Input('ListFiles') ListFiles: FilesUploadModel[] = [];

  _fileName: string = "";

  filesToUpload: File[] = [];
  ngOnInit() {
  }


  fileChangeEvent(fileInput: any) {
    this.filesToUpload = <File[]>fileInput.target.files;
    let file = this.filesToUpload[0]
    let fileextension = file.name.split('.').pop()
    this._fileName = file.name;
    if(this.IsAllowPdf && !this.extensions.includes('pdf'))
      this.extensions.push('pdf');
    if (!this.extensions.find(x => x === fileextension.toLowerCase())) {
      this.alert.error(`wrong format, you should upload ${this.extensions.join(',')} only.`);
      this.myFileControl.nativeElement.value = "";
      this.filesToUpload = [];
      this._fileName = "";
        return;
    }
    if (this.maxFileSize && file.size > this.maxFileSize) {
      this.alert.error(`Maximum file size allowed is ${file.size / 1024 / 1024} MB`);
      this.myFileControl.nativeElement.value = "";
      this.filesToUpload = [];
      this._fileName = "";
       return;
    }

  }

  remove(objectToDeleteIndex: any) {
    debugger;
    if (this.ListFiles == null || objectToDeleteIndex >= this.ListFiles.length) return;
    let objectToDelete = this.ListFiles[objectToDeleteIndex];
    if (objectToDelete.ID && objectToDelete.ID !== 0) {
      objectToDelete.IsDeleted = true;
      return;
    }
    
    this._uploadFilesSrvc.deleteFiles(objectToDelete.PathName, objectToDelete.ID)
      .subscribe(res => {
        this.ListFiles.splice(objectToDeleteIndex, 1);
      });
  }

  upload() {
    debugger;
    if (this.filesToUpload && this.filesToUpload.length > 0) {
      let files: File[] = this.filesToUpload;

      this._uploadFilesSrvc.uploadFiles(files).subscribe(res => {
        this.FilesUpload = res;
        if (!this.ListFiles)
          this.ListFiles = [];
        this.ListFiles.push(Object.assign({}, this.FilesUpload));
        this.myFileControl.nativeElement.value = "";
        this.filesToUpload = [];
        this._fileName = "";
      },
        err => {
          this.alert.error('Something went wrong, please try again later. If the problem continues please check with your system administrator');
        });
    }
  }

  filterDeleted(files: FilesUploadModel[]) {
    return files.filter(x => !x.IsDeleted);
  }

  download(fileName: string, filePath: string, Id: number) {
    debugger;
    this._uploadFilesSrvc.download(Id == 0 ? filePath : '', fileName, Id, this.uploadType).subscribe(res => {
      var blob = new Blob([res]);
      this.saveFile(blob, fileName);
    },
      err => {
        if (err.status == 404) {
          this.alert.error('File Not Found ');
          return;
        }
      });
  }

  saveFile(blob: Blob, fileName: string) {
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  }
}


