import { Students } from "./students.model";

export class Bus 
{
    plateNo : string;
    code : string;
    Id : string ;
    StartLandmark : string; 
    DestLandmark : string;  
    StartLandmarkName : string; 
    DestLandmarkName : string; 
    Seats : number;
    Driver: string;
    Supervisor : string; 
    Number: string;
    PlatNumber: string;
    CategoryId: number = -1;
    SubscriberId: number = 1;
    Selected: boolean = false;
    SeatCapacity: number;
    DriverName: string;
    DriverId  :string; 
    SupervisorId : string;
    SupervisorName : string;
    StartLocationLatitude : string;
    StartLocationLongitude : string;
    EndLocationLatitude : string;
    EndLocationLongitude : string;
    Route: WorkOrderRouteObject = new WorkOrderRouteObject();
    Students: Students[] = [];
    StartTime: string; 
    EndTime:string;
    displayResult: boolean;
}
 
  
export class WorkOrderRouteObject {
    Id: number;
    RouteLine: LineShape;
    RouteName: string;
    TotalTime: number; //in minutes
    TotalDistance: number;
    RouteStyle: string = null;
    POITypeId: number=1006;
    BufferSize: number = 20;

}
export class LineShape {
    LineGeometry: string;
}
 