import { Injectable, Output, EventEmitter } from '@angular/core';
import { Plans } from '../dataModel/plans.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Configuration } from '../../shared/configurations/shared.config';
import { Bus, WorkOrderRouteObject, LineShape } from '../dataModel/buss.model';
import { Students } from '../dataModel/students.model';
import { Grade } from '../dataModel/grade.model';
import { filterBus } from '../dataModel/filterBus.model';
import { filterStudents } from '../dataModel/filterStudents.model';
import { retry } from '../../../../node_modules/rxjs/internal/operators/retry';
import { MapService } from '../../shared/Services/mapservice/map-service';
import { PlanViewModel, PlanTripsViewModel } from '../dataModel/planTrips.model';
import { AuthenticationService } from '../../shared/Services/authentication/authentication.service';
import { LoaderService } from '../../shared/loader.service';
import { alertService } from '../../shared/Services/alert/alert.service';
import { PlannedTripStudentModel } from '../dataModel/PlannedTripStudentModel';
import { PlannedTripsModel } from '../dataModel/PlannedTripModel';
import { Buses } from '../dataModel/student-list.model';
import { BusModel } from '../dataModel/bus-model';

@Injectable({
  providedIn: 'root'
})
export class PlandetailsService {
  private _plan: Plans;
  @Output() planResult: EventEmitter<any> = new EventEmitter();
  @Output() RecreatePlanResult: EventEmitter<any> = new EventEmitter();
  REQUEST_WAIT_TIME = 40000;// MiliSecond
  numberOfTrails: number = 0;
  MAX_NUMBER_OF_TRAILS = 15;
  BusesList: Bus[] = [];
  busModel: BusModel = undefined;
  StudentsList: any[] = [];
  depotsJsonObject: string;
  ordersJsonObject: string;
  routesJsonObject: string;
  associationList: any[] = [];
  routesGraphicLayer: any;
  stopsGraphicLayer: any;
  private StudentServiceTime: number = 5; // mi nutes
  private MaxTotalTime: number = 120; // minute
  startTime: number;
  endTime: number;

  sPlanViewModel: PlanViewModel = new PlanViewModel();
  startIconSrc: string = "/src/assets/start-icon.png";
  endIconSrc: string = "/src/assets/end-icon.png";
  studentIconSrc: string = "/src/assets/student.png";
  busIconSrc: string = "/src/assets/bus.png";
  currentLevel: number = 1;
  constructor(
    private alert: alertService,
    private _loader: LoaderService,
    private _authenticationService: AuthenticationService, private http: HttpClient, private _mapService: MapService) { }

  public setObj(plan: Plans): void {
    this._plan = plan;
  }

  public getObj(): Plans {
    return this._plan;
  }




  getStudentByBranchIdAndSubBranchId(filterStudents: filterStudents) {

    return this.http.post(Configuration.urls.webApiEndpoint + Configuration.urls.Plans.apiGetStudentByBranchIdAndSubBranchIdURL
      , filterStudents);
  }

  getBusByBranchIdAndSubBranchId(filterBus: filterBus) {
    return this.http.post(Configuration.urls.webApiEndpoint + Configuration.urls.Plans.apiGetBusByBranchIdAndSubBranchIdURL, filterBus);
  }


  getStudentByBranchIdAndSubBranchIdAll(branchID: string, subBranchID: string, SubId: string, PlanTypeId: number) {
    // Initialize Params Object
    let Params = new HttpParams();
    // Begin assigning parameters
    Params = Params.append('branchID', branchID);
    Params = Params.append('subBranchID', subBranchID);
    Params = Params.append('SubId', SubId);
    Params = Params.append('PlanTypeId', PlanTypeId.toString());

    return this.http.get<Students[]>(Configuration.urls.webApiEndpoint + Configuration.urls.Plans.apiGetStudentByBranchIdAndSubBranchIdURL, { params: Params });
  }

  getGradeByBranchIdAndSubBranchIdAll(branchID: string, subBranchID: string, SubId: string) {
    // Initialize Params Object
    let Params = new HttpParams();
    // Begin assigning parameters
    Params = Params.append('branchID', branchID);
    Params = Params.append('subBranchID', subBranchID);
    Params = Params.append('SubId', SubId);

    return this.http.get<Grade[]>(Configuration.urls.webApiEndpoint + Configuration.urls.Plans.apiGetGradeByBranchIdAndSubBranchIdURL, { params: Params });
  }



  getBusByBranchIdAndSubBranchIdAll(branchID: string, subBranchID: string, SubId: string) {
    // Initialize Params Object
    let Params = new HttpParams();
    // Begin assigning parameters
    Params = Params.append('branchID', branchID);
    Params = Params.append('subBranchID', subBranchID);
    Params = Params.append('SubId', SubId);

    return this.http.get<Bus[]>(Configuration.urls.webApiEndpoint + Configuration.urls.Plans.apiGetBusByBranchIdAndSubBranchIdURL, { params: Params });
  }

  savePlan(planViewModel: PlanViewModel) {
    return this.http.post(Configuration.urls.webApiEndpoint + Configuration.urls.Plans.apiPostPlan, planViewModel);
  }
  public CreatePlan(StudentsList: Students[], BusesList: Bus[], Constrains: Plans) {
    //debugger
    this.StudentServiceTime = Constrains.WaitingTimeMins;
    this.MaxTotalTime = Constrains.MaxTripTimeMins;
    this.StudentsList = StudentsList;
    if(Constrains.TripStartTime != ""){
      let date = new Date().setHours(Number(Constrains.TripStartTime.split(':')[0]), Number(Constrains.TripStartTime.split(':')[1]));

      let hhh = new Date(date);
      console.log(hhh);
      this.startTime = date; //new Date(2018,9,15,Number(),Number(Constrains.TripStartTime.split(':')[1])).getTime();
       
      this.endTime = this.startTime + (Constrains.MaxTripTimeMins * 60000);
    }else {
      let date = new Date().setHours(Number(Constrains.TripEndTime.split(':')[0]), Number(Constrains.TripEndTime.split(':')[1]));

      let hhh = new Date(date);
      console.log(hhh);
      this.endTime = date; //new Date(2018,9,15,Number(),Number(Constrains.TripStartTime.split(':')[1])).getTime();
      this.startTime = this.endTime - (Constrains.MaxTripTimeMins * 60000);
    }
    

    this.BusesList = BusesList;
    if (this.routesGraphicLayer)
      this._mapService.getMapObject().removeLayer(this.routesGraphicLayer);
    this.routesGraphicLayer = this._mapService.createGraphicLayer();
    
    if (this._mapService.drawingGraphicsLayer)
      this._mapService.clearLayerGraphics(this._mapService.drawingGraphicsLayer);

    var planningParams = 'time_units=' + Configuration.keys.LogisticsServiceTimeUnits +
      '&distance_units=' + Configuration.keys.LogisticsServiceDistanceUnits +
      '&uturn_policy=' + Configuration.keys.LogisticsServiceUturnPolicy +
      '&populate_directions=' + Configuration.keys.LogisticsServicePopulateDirections +
      '&directions_language=' + Configuration.keys.LogisticsServiceDirectionsLanguage +
      '&default_date=' + this.startTime + '&f=json';

    this.BuildOrdersObject(StudentsList, Constrains.PlanTypeId);
    this.BuildDepotsAndRoutes(BusesList, Constrains);
    var requestUrl =/* "submitJob?"*/
        //   + "token=" + PlanningConfig.keys.LogisticsServiceToken
        /*+*/ "&orders=" + this.ordersJsonObject
      + "&depots=" + this.depotsJsonObject
      + "&routes=" + this.routesJsonObject
      + "&" + planningParams;

    // Submit Job Request
    var xthis = this;
    this.numberOfTrails = 0;

    // Parameters obj-  { serviceUrl: requestUrl };
    let requestJson = { serviceUrl: requestUrl };

    console.log(requestUrl)
    return this.http.post(Configuration.urls.webApiEndpoint + Configuration.urls.Plans.apiGetJobUtiliesUrl, requestJson)
      .subscribe(response => {
        console.log(response);

        if (response["jobStatus"] == 'esriJobSubmitted') {

          var jobId = response["jobId"];
          this._loader.PreloaderIcreaseCount();
          // wait some time for preparing results
          setTimeout(() => {
          this._loader.PreloaderDecreaseCount();

            xthis.GetRoutesResults(jobId , Constrains.PlanTypeId);
          }, xthis.REQUEST_WAIT_TIME);
          // 
        }
        else if (response && response["error"] && response["error"].code == 403) {
          alert("you must change client id and clientd secret");
        }
        else {
          alert("Error in gettin results Routes");
        }
        //}
      },
        error => {
          console.log(error);
          this.alert.error('Error, the system was not able to generate route. Please check your inputs. If the problem continue, please try again later or check with your provider support team.');
        });
  }
  private BuildOrdersObject(StudentsList: Students[], planTypId: number) {

    if (this.stopsGraphicLayer)
      this._mapService.getMapObject().removeLayer(this.stopsGraphicLayer);
    this.stopsGraphicLayer = this._mapService.createGraphicLayer(999);

    this._mapService.setRoutesGraphicLayer(this.stopsGraphicLayer.getSource())
    var orders = [];
    for (var student of StudentsList) {
      var location = { "x": planTypId == 1 ? student.longitudePickUp : student.longitudeDropOff, "y": planTypId == 1 ? student.latitudePickUp : student.latitudeDropOff };
      var attributes = { "PickupQuantities": 1, "Name": student.Id, "ServiceTime": this.StudentServiceTime };
      var orderObject = { "geometry": location, "attributes": attributes };
      orders.push(orderObject);
      // this._mapService.addFeatureOnMap(student.Id, 'POINT(' + location.x + ' ' + location.y + ')', this.stopsGraphicLayer.getSource(), "#4271AE", student.FullName , null );
    }
    this.ordersJsonObject = JSON.stringify({ "features": orders });
  }

  private BuildDepotsAndRoutes(BusesList: Bus[], Constrains: Plans) {

    var depots = [];
    var routes = [];
    var depotObjectStart, depotObjectEnd;
    for (var Bus of BusesList) {
      // set Source Depot
      var startLocation = { "x": Bus.StartLocationLongitude, "y": Bus.StartLocationLatitude };
      var startAttributes = { "Name": "Start_" + Bus.Id };
      depotObjectStart = { "geometry": startLocation, "attributes": startAttributes };
      depots.push(depotObjectStart);
      this._mapService.addFeatureOnMap(Bus.StartLandmark + "Source", 'POINT(' + startLocation.x + ' ' + startLocation.y + ')', this.stopsGraphicLayer.getSource(), "#A7ED02", " ", Bus.StartLandmark == Bus.DestLandmark ? -15 : 0, this.startIconSrc);
      if (Bus.StartLandmark != Bus.DestLandmark) {

        var endLocation = { "x": Bus.EndLocationLongitude, "y": Bus.EndLocationLatitude };
        var endAttributes = { "Name": "End_" + Bus.Id };
        depotObjectEnd = { "geometry": endLocation, "attributes": endAttributes };
        depots.push(depotObjectEnd);
        this._mapService.addFeatureOnMap(Bus.DestLandmark + "Dest", 'POINT(' + endLocation.x + ' ' + endLocation.y + ')', this.stopsGraphicLayer.getSource(), "#f00000", " ", Bus.StartLandmark == Bus.DestLandmark ? 25 : 0, this.endIconSrc);
      }
      // Build Routes Object
      if (!depotObjectEnd) {
        depotObjectEnd = depotObjectStart;
      }
      //debugger
      var routeAttributes = {
        "Name": "RouteForBus_" + Bus.Id,
        "StartDepotName": depotObjectStart.attributes.Name,
        "EndDepotName": depotObjectEnd.attributes.Name,
        "StartDepotServiceTime": this.StudentServiceTime,
        "EarliestStartTime": this.startTime,
        "LatestStartTime": this.endTime,
        "Capacities": Bus.SeatCapacity,
        "MaxTotalTime": this.MaxTotalTime,
        "CostPerUnitTime": 0.2,
        "CostPerUnitDistance": 1.5
      };
      var routeObject = { "attributes": routeAttributes };
      routes.push(routeObject);
    }
    this.routesJsonObject = JSON.stringify({ "features": routes });
    this.depotsJsonObject = JSON.stringify({ "features": depots });
  }


  //#region RecreatePlan

  MapBus(plan: PlannedTripsModel) {
    var bus = new BusModel();
    bus.Id = plan.BusId;
    // bus.DestLandmarkName;
    // bus.StartLandmarkName;
    bus.plateNo = plan.PlateNo;
    bus.SeatCapacity = plan.SeatNumber;
    // bus.code;
    bus.DriverName = plan.DriverName;
    bus.SupervisorName = plan.SupervisorName;
    bus.DriverId = plan.DriverId;
    bus.SupervisorId = plan.SupervisorId;
    bus.StartLandmark = plan.StartLandmark;
    bus.DestLandmark = plan.DestLandmark;

    this.busModel = bus;
  }

  public RecreatePlan(StudentsList: PlannedTripStudentModel[], Constrains: PlannedTripsModel) {
    //debugger
    this.StudentServiceTime = Constrains.WaitingTimeMins;
    this.MaxTotalTime = Constrains.MaxTripTimeMins;
    this.StudentsList = StudentsList;
    let date = new Date().setHours(Number(Constrains.TripStartTime.split(':')[0]), Number(Constrains.TripStartTime.split(':')[1]));
    this.startTime = date; //new Date(2018,9,15,Number(),Number(Constrains.TripStartTime.split(':')[1])).getTime();
    this.endTime = this.startTime + (Constrains.MaxTripTimeMins * 60000);

    this.MapBus(Constrains);

    if (this.routesGraphicLayer)
      this._mapService.getMapObject().removeLayer(this.routesGraphicLayer);
    this.routesGraphicLayer = this._mapService.createGraphicLayer();

    if (this._mapService.drawingGraphicsLayer)
      this._mapService.clearLayerGraphics(this._mapService.drawingGraphicsLayer);

    var planningParams = 'time_units=' + Configuration.keys.LogisticsServiceTimeUnits +
      '&distance_units=' + Configuration.keys.LogisticsServiceDistanceUnits +
      '&uturn_policy=' + Configuration.keys.LogisticsServiceUturnPolicy +
      '&populate_directions=' + Configuration.keys.LogisticsServicePopulateDirections +
      '&directions_language=' + Configuration.keys.LogisticsServiceDirectionsLanguage +
      '&default_date=' + this.startTime + '&f=json';

    this.RebuildOrdersObject(StudentsList, Constrains.PlanTypeId);
    this.RebuildDepotsAndRoutes(Constrains);
    var requestUrl =/* "submitJob?"*/
        //   + "token=" + PlanningConfig.keys.LogisticsServiceToken
        /*+*/ "&orders=" + this.ordersJsonObject
      + "&depots=" + this.depotsJsonObject
      + "&routes=" + this.routesJsonObject
      + "&" + planningParams;

    // Submit Job Request
    var xthis = this;
    this.numberOfTrails = 0;

    let requestJson = { serviceUrl: requestUrl };

    return this.http.post(Configuration.urls.webApiEndpoint + Configuration.urls.Plans.apiGetJobUtiliesUrl, requestJson)
      .subscribe(response => {
        console.log(response);

        if (response["jobStatus"] == 'esriJobSubmitted') {

          var jobId = response["jobId"];
          this._loader.PreloaderIcreaseCount();
          // wait some time for preparing results
          setTimeout(() => {
            this._loader.PreloaderDecreaseCount();

            xthis.GetBusRoutesResults(jobId);
          }, xthis.REQUEST_WAIT_TIME);
          // 
        }
        else if (response && response["error"] && response["error"].code == 403) {
          alert("you must change client id and clientd secret");
        }
        else {
          alert("Error in gettin results Routes");
        }
        //}
      },
        error => {
          console.log(error);
          this.alert.error('Error, the system was not able to generate route. Please check your inputs. If the problem continue, please try again later or check with your provider support team.');
        });
  }

  private RebuildOrdersObject(StudentsList: PlannedTripStudentModel[], planTypId: number) {

    if (this.stopsGraphicLayer)
      this._mapService.getMapObject().removeLayer(this.stopsGraphicLayer);
    this.stopsGraphicLayer = this._mapService.createGraphicLayer(999);

    this._mapService.setRoutesGraphicLayer(this.stopsGraphicLayer.getSource())
    var orders = [];
    for (var student of StudentsList) {
      var location = { "x": student.LocationLongitude, "y": student.LocationLatitude };
      var attributes = { "PickupQuantities": 1, "Name": student.Id, "ServiceTime": this.StudentServiceTime };
      var orderObject = { "geometry": location, "attributes": attributes };
      orders.push(orderObject);
      // this._mapService.addFeatureOnMap(student.Id, 'POINT(' + location.x + ' ' + location.y + ')', this.stopsGraphicLayer.getSource(), "#4271AE", student.FullName , null );
    }
    this.ordersJsonObject = JSON.stringify({ "features": orders });
  }

  private RebuildDepotsAndRoutes(Constrains: PlannedTripsModel) {

    var routes = [];
    var depots = [];
    var depotObjectStart, depotObjectEnd;
    var startLocation = { "x": Constrains.StartLocationLongitude, "y": Constrains.StartLocationLatitude };
    var startAttributes = { "Name": "Start_" + Constrains.BusId };
    depotObjectStart = { "geometry": startLocation, "attributes": startAttributes };
    depots.push(depotObjectStart);
    this._mapService.addFeatureOnMap(Constrains.StartLandmark + "Source", 'POINT(' + startLocation.x + ' ' + startLocation.y + ')', this.stopsGraphicLayer.getSource(), "#A7ED02", " ", Constrains.StartLandmark == Constrains.DestLandmark ? -15 : 0, this.startIconSrc);
    if (Constrains.StartLandmark != Constrains.DestLandmark) {

      var endLocation = { "x": Constrains.EndLocationLongitude, "y": Constrains.EndLocationLatitude };
      var endAttributes = { "Name": "End_" + Constrains.BusId };
      depotObjectEnd = { "geometry": endLocation, "attributes": endAttributes };
      depots.push(depotObjectEnd);
      this._mapService.addFeatureOnMap(Constrains.DestLandmark + "Dest", 'POINT(' + endLocation.x + ' ' + endLocation.y + ')', this.stopsGraphicLayer.getSource(), "#f00000", " ", Constrains.StartLandmark == Constrains.DestLandmark ? 25 : 0, this.endIconSrc);
    }
    // Build Routes Object
    if (!depotObjectEnd) {
      depotObjectEnd = depotObjectStart;
    }
    //debugger
    var routeAttributes = {
      "Name": "RouteForBus_" + Constrains.BusId,
      "StartDepotName": depotObjectStart.attributes.Name,
      "EndDepotName": depotObjectEnd.attributes.Name,
      "StartDepotServiceTime": this.StudentServiceTime,
      "EarliestStartTime": this.startTime,
      "LatestStartTime": this.endTime,
      "Capacities": Constrains.SeatNumber,
      "MaxTotalTime": this.MaxTotalTime,
      "CostPerUnitTime": 0.2,
      "CostPerUnitDistance": 1.5
    };
    var routeObject = { "attributes": routeAttributes };
    routes.push(routeObject);

    this.routesJsonObject = JSON.stringify({ "features": routes });
    this.depotsJsonObject = JSON.stringify({ "features": depots });
  }

  public GetBusRoutesResults(jobId) {
    var xthis = this;
    // 'j1922cb60a8b54594a0e2fffaf208e16c' 
    var requestUrl = jobId + "/results/out_routes?&f=json"

    const options = requestUrl ? { params: new HttpParams().set('serviceUrl', requestUrl) } : {};
    return this.http.get(Configuration.urls.webApiEndpoint + Configuration.urls.Plans.apiGetRouteUtiliesUrl, options).pipe(
      retry(xthis.MAX_NUMBER_OF_TRAILS), // retry a failed request up to 3 times

    ).subscribe(res => {

      console.log(res);
      if (res["value"] != null) {
        //debugger;  
        this.numberOfTrails = 0;
        var Routes = res["value"].features;

        if (this.routesGraphicLayer)
          this._mapService.getMapObject().removeLayer(this.routesGraphicLayer);
        this.routesGraphicLayer = this._mapService.createGraphicLayer();
        var wktRoutes = this._mapService.UpdateGraphicLayerSource(this.routesGraphicLayer, Routes, true);

        //debugger  
        for (var route of wktRoutes) {
          if (xthis.busModel && xthis.busModel.Id == route.BusId) {

            xthis.busModel.Route = new WorkOrderRouteObject();
            var line = new LineShape();
            line.LineGeometry = route.wkt;
            xthis.busModel.Route.RouteLine = line;
            xthis.busModel.Route.TotalTime = route.attributes.values_.TotalTime; //in minutes
            xthis.busModel.Route.TotalDistance = route.attributes.values_.TotalDistance;
            xthis.busModel.Route.RouteStyle = this._mapService.getRandomColor();
            xthis.busModel.StartTime = route.attributes.values_.StartTime;
            xthis.busModel.EndTime = route.attributes.values_.EndTime;

            this.centerMap(xthis.busModel.StartLocationLongitude, xthis.busModel.StartLocationLatitude, 12);
          }
        }
        // call to get Relation between Bus and Students
        this.GetBusStopsResults(jobId);

      }
      else {
        this.alert.error("Error in getting results Routes");
      }

    }, error => {
      console.log(error);
      this.alert.error('Error, the system was not able to generate route. Please check your inputs. If the problem continue, please try again later or check with your provider support team.');
    });

  }

  public GetBusStopsResults(jobId) {
    debugger

    // Parameters obj-
    var xThis = this;
    var requestUrl = jobId + "/results/out_stops?&f=json";
    const options = requestUrl ? { params: new HttpParams().set('serviceUrl', requestUrl) } : {};

    return this.http.get(Configuration.urls.webApiEndpoint + Configuration.urls.Plans.apiGetStopUtiliesUrl, options).pipe(
      retry(xThis.MAX_NUMBER_OF_TRAILS), // retry a failed request up to 3 times

    ).subscribe(response => {
      console.log(response);

      if (response["value"] != null) {
        //reset students for each bus
        xThis.busModel.Students = [];
        // the stops contain all students location and bus start and end
        var stops = response["value"].features;

        for (var stop of stops) {

          var orderId = Number(stop.attributes.Name); // student Id
          var busId: string = stop.attributes.RouteName.split("_")[1]; // busId
          if (xThis.busModel.Id == busId) {
            var bus = xThis.busModel;
            bus.Route.RouteName = stop.attributes.RouteName;
            if (!bus.Students) {
              bus.Students = [];
            }

            if (!Number.isNaN(orderId)) {

              var student = this.StudentsList.find(x => x.Id == orderId);
              if (student) {

                student.BusArrivalTime = stop.attributes.ArriveTime;
                student.ArrivalTime = new Date(student.BusArrivalTime);
                bus.Students.push(student);

              }
            }
          }
        }

        if (xThis.busModel) {

          var bus = xThis.busModel;

          if (bus.Students)
            bus.Students = bus.Students.sort(function (a, b) {
              return a.BusArrivalTime - b.BusArrivalTime
            });
          let order = 1
          bus.Students.forEach(res => {

            var location = { "x": res.LocationLongitude, "y": res.LocationLatitude };
            this._mapService.addFeatureOnMap(res.Id, 'POINT(' + location.x + ' ' + location.y + ')', this.stopsGraphicLayer.getSource(), "#4271AE", order.toString(), null);
            order++;
          })
        }
        xThis.RecreatePlanResult.emit(xThis.busModel);
      }
      else {
        alert("Error in gettin results Stops");
      }

    },
      error => {
        alert('error');
      });
  }

  //#endregion


  public GetRoutesResults(jobId , planTypId) {
    var xthis = this;
    // 'j1922cb60a8b54594a0e2fffaf208e16c' 
    var requestUrl = jobId + "/results/out_routes?&f=json"

    const options = requestUrl ? { params: new HttpParams().set('serviceUrl', requestUrl) } : {};
    return this.http.get(Configuration.urls.webApiEndpoint + Configuration.urls.Plans.apiGetRouteUtiliesUrl, options).pipe(
      retry(xthis.MAX_NUMBER_OF_TRAILS), // retry a failed request up to 3 times

    ).subscribe(res => {

      console.log(res);
      if (res["value"] != null) {
        //debugger;  
        this.numberOfTrails = 0;
        var Routes = res["value"].features;

        if (this.routesGraphicLayer)
          this._mapService.getMapObject().removeLayer(this.routesGraphicLayer);
        this.routesGraphicLayer = this._mapService.createGraphicLayer();  
        var wktRoutes  = this._mapService.UpdateGraphicLayerSource(this.routesGraphicLayer, Routes, true);
         
        //debugger  
        for (var route of wktRoutes) {
          var bus = xthis.BusesList.find(b => b.Id == route.BusId);
          if (bus) {

            bus.Route = new WorkOrderRouteObject();
            var line = new LineShape();
            line.LineGeometry = route.wkt;
            bus.Route.RouteLine = line;
            // bus.Route.TotalTime = route.attributes.TotalTime; //in minutes
            // bus.Route.TotalDistance = route.attributes.TotalDistance;
            // bus.Route.RouteStyle = route.attributes.Color;
            // bus.StartTime = route.attributes.StartTime;
            // bus.EndTime = route.attributes.EndTime;

            bus.Route.TotalTime = route.attributes.values_.TotalTime; //in minutes
            bus.Route.TotalDistance = route.attributes.values_.TotalDistance;
            bus.Route.RouteStyle = this._mapService.getRandomColor();
            bus.StartTime = route.attributes.values_.StartTime;
            bus.EndTime = route.attributes.values_.EndTime;

            this.centerMap(bus.StartLocationLongitude, bus.StartLocationLatitude, 12);
          }
        }
        // call to get Relation between Bus and Students
        this.GetStopsResults(jobId , planTypId);
      }
      else {
        this.alert.error("Error in getting results Routes");
      }

    },error=>{
      console.log(error);
      this.alert.error('Error, the system was not able to generate route. Please check your inputs. If the problem continue, please try again later or check with your provider support team.');
    });

  }


  centerMap(lon, lat, zoom) {
    this._mapService.centerMap(lon, lat, zoom);
  }
  public GetStopsResults(jobId , planTypId) {

    // Parameters obj-
    var xThis = this;
    var requestUrl = jobId + "/results/out_stops?&f=json";
    const options = requestUrl ? { params: new HttpParams().set('serviceUrl', requestUrl) } : {};

    return this.http.get(Configuration.urls.webApiEndpoint + Configuration.urls.Plans.apiGetStopUtiliesUrl, options).pipe(
      retry(xThis.MAX_NUMBER_OF_TRAILS), // retry a failed request up to 3 times

    ).subscribe(response => {
      console.log(response);

      if (response["value"] != null) {
        //reset students for each bus
        this.BusesList.forEach(bus => {
          bus.Students = [];
        });
        // the stops contain all students location and bus start and end
        var stops = response["value"].features;
        
        for (var stop of stops) {

          var orderId = Number(stop.attributes.Name); // student Id
          var busId: string = stop.attributes.RouteName.split("_")[1]; // busId
          var bus = xThis.BusesList.find(x => x.Id == busId);
          bus.Route.RouteName = stop.attributes.RouteName;
          if (!bus.Students){
            bus.Students = [];
             
          }
            
          //debugger
          if (!Number.isNaN(orderId)) {
            
            var student = this.StudentsList.find(x => x.Id == orderId);
            if (student) {
              
              student.BusArrivalTime = stop.attributes.ArriveTime;
              student.ArrivalTime = new Date(student.BusArrivalTime);
              bus.Students.push(student);
              
            }
          }
          // var arrivalTime = stop.attributes.ArriveTimeUTC;
        }//debugger
        
        for (var bus of xThis.BusesList) {

          if (bus.Students)
            bus.Students = bus.Students.sort(function (a, b) {
              return a.BusArrivalTime - b.BusArrivalTime
            });
            let order = 1
            bus.Students.forEach(res=>{
              
              var location = { "x": planTypId == 1 ? res.longitudePickUp : res.longitudeDropOff, "y": planTypId == 1 ? res.latitudePickUp : res.latitudeDropOff };
              this._mapService.addFeatureOnMap(res.Id, 'POINT(' + location.x + ' ' + location.y + ')', this.stopsGraphicLayer.getSource(), "#4271AE", order.toString() , null );
              order++;
            })
        }
        xThis.planResult.emit(xThis.BusesList);
        //this.MapObjectToSendServer(,xThis.BusesList)
        console.log(xThis.BusesList);
      }
      else {
        alert("Error in gettin results Stops");
      }

    },
      error => {
        alert('error');
      });
  }
  MapObjectToSendServer(plan: Plans, bus: Bus[]): PlanViewModel {
    //debugger
    this.sPlanViewModel.Code = plan.Code;
    this.sPlanViewModel.Name = plan.Name;
    this.sPlanViewModel.Id = plan.Id;
    this.sPlanViewModel.BranchId = plan.subBranchID != null ? plan.subBranchID : plan.BranchId;
    this.sPlanViewModel.SubId = this._authenticationService.SubId;
    this.sPlanViewModel.PlanTypeId = plan.PlanTypeId;
    this.sPlanViewModel.weekStr = plan.weeks;
    this.sPlanViewModel.weekStrNew = plan.weekStr;
    this.sPlanViewModel.Description = plan.Description;
    this.sPlanViewModel.TripStartTime = plan.TripStartTime
    this.sPlanViewModel.TripEndTime = plan.TripEndTime;
    this.sPlanViewModel.PlanStartDate = plan.PlanStartDate;
    this.sPlanViewModel.PlanEndDate = plan.PlanEndDate;
    this.sPlanViewModel.WaitingTimeMins = plan.WaitingTimeMins;
    this.sPlanViewModel.MaxTripTimeMins = plan.MaxTripTimeMins;
    this.sPlanViewModel.PlanTrips = [];
    this.sPlanViewModel.CreatedBy = this._authenticationService.UserId;
    //sPlanViewModel.weeks: string;
    for (let i = 0; i < bus.length; i++) {
      //debugger;
      let PlanTrip: PlanTripsViewModel = new PlanTripsViewModel();
      const element = bus[i];
      PlanTrip.SeatCapacity = element.SeatCapacity;
      PlanTrip.SourcePOI = element.StartLandmark;
      PlanTrip.DestinationPOI = element.DestLandmark;
      PlanTrip.StartLandmarkName = element.StartLandmarkName;
      PlanTrip.DestLandmarkName = element.DestLandmarkName;
      PlanTrip.StartTime = new Date(element.StartTime);
      PlanTrip.EndTime = new Date(element.EndTime);
      PlanTrip.DriverId = element.DriverId;
      PlanTrip.SupervisorId = element.SupervisorId;
      PlanTrip.SupervisorName = element.SupervisorName;
      PlanTrip.DriverName = element.DriverName;
      PlanTrip.BusId = element.Id;
      PlanTrip.code = element.code;
      PlanTrip.plateNo = element.plateNo;

      PlanTrip.RouteString = JSON.stringify(element.Route);
      //debugger
      PlanTrip.TotalTime = element.Route == null ? 0 : Math.round(element.Route.TotalTime);
      PlanTrip.TotalDistance = element.Route == null ? 0 : Math.round(element.Route.TotalDistance);


      PlanTrip.StudentsCount = element.Students.length;
      PlanTrip.PlannedTripStudents = element.Students;
      this.sPlanViewModel.PlanTrips.push(PlanTrip);


    }
    return this.sPlanViewModel;
  }
}
