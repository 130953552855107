import { Component, EventEmitter, Output, Input, OnInit, ChangeDetectorRef } from '@angular/core';
// import { TranslateService } from '../shared/Services/translate/translate.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../shared/Services/authentication/authentication.service'; 
import { Configuration } from '../shared/configurations/shared.config';

@Component({
  selector: 'main-header',
  templateUrl: './header.component.html'
 
})
export class AppHeader {
  logoSrc: any = Configuration.Logo.src
  @Output() menuStatus = new EventEmitter<Boolean>();
  @Output() hideMenustate = new EventEmitter<Boolean>();
  menuState = false;
 
    constructor(private translate: TranslateService,  public auth: AuthenticationService, private _cd: ChangeDetectorRef) {
  }

  toggleMenu() {
    this.menuStatus.emit(!this.menuState)

  }

  hideMenu() {
    this.hideMenustate.emit(false)
  }

  changelang(key: string) { 
    this.auth.setCulture(key);
    this.translate.use(key);
     
    this._cd.markForCheck();
  }
  logout() {
    this.auth.logout();
  }
}
