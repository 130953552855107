import { Injectable, inject } from '@angular/core';
import { Configuration } from '../../configurations/shared.config';
import { MenuLinks } from '../../Resources/menu.res'
@Injectable()
export class MenuLinkService {

    public getLink(key: string, system: string) {
        let port: string = '';
        if (system.toLowerCase() == 'Altair'.toLowerCase())
            port = Configuration.Ports.AltairPort;
        else if (system.toLowerCase() == 'GPS'.toLowerCase())
            port = Configuration.Ports.GpsPort;
        else if (system.toLowerCase() == 'CarRent'.toLowerCase())
            port = Configuration.Ports.RenCarsPort;

        if (MenuLinks[key])
            return port + MenuLinks[key];
        else
            return '';
    }
}